import React from 'react';

import Layout from "../layouts/site/layout";

function Production() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-4">
                <h2 className="text-red-1000 font-bold text-base">Production facilities & Resources</h2>
                <p className="mt-2">We have a wide range of 21st Century state of the art equipment including but not limited to:</p>
                <ul className="mt-2 ml-9 list-none">
                    <li>Modern IT capabilities and computerised scheduling</li>
                    <li>Automated Sawing</li>
                    <li>A range of open die forging hammers ranging from 1 Tonne to 5 Tonne with a forging energy in excess of 150 kilojoules on the 5 Tonne.</li>
                    <li>Heat treatment</li>
                    <li>CNC Machining</li>
                    <li>Non-destructive testing</li>
                    <li>Metalurgical & Mechanical Testing Laboratory</li>
                </ul>
                <h3 className="text-[15px] text-center font-bold mt-3">Forging Solutions Transforming Business</h3>
            </div>
       </Layout>
    </div>
  );
}

export default Production;
